(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('mhDeleteMortgageModal', mhDeleteMortgageModal);
    function mhDeleteMortgageModal () {
        return {
            restrict: 'E',
            scope: { mortgage: '<', policyId: '<' },
            templateUrl: 'js/modals/delete-mortgage-modal/template.html',
            controller: function ($scope, $mdDialog, toaster, APIService, spinnerService) {
                $scope.confirmDelete = () => {
                    spinnerService.show('globalSpinner');
                    const mortgageUpdate = {property_data: {mortgages: { details: {[$scope.mortgage.id]: null}}}};
                    APIService.updatePolicyMortgages($scope.policyId, mortgageUpdate).then((updatedPolicy) => {
                        toaster.pop('success', 'Mortgages', 'Deleted');
                        spinnerService.hide('globalSpinner');
                        $mdDialog.hide(updatedPolicy);
                    }).catch(err => {
                        spinnerService.hide('globalSpinner');
                        toaster.pop('error', 'Mortgages', err || 'Unable to save');
                    });
                };

                $scope.cancel = () => $mdDialog.cancel();
            }
        };
    }
})(window.angular, window._);
